import API from '../services/services'

class KelolaRumahSakitService {
  get() {
    return API.get('manage_hospital/detail_hospital')
  }

  updateHospital(payload) {
    return API.post('manage_hospital/list_hospital/update', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  updateHospitalSchedule(payload) {
    return API.post('manage_hospital/list_hospital/schedule/update', payload)
  }

  // manage hospital for dropping
  listDroppingRS(params) {
    return API.get('hospital_blood_receivers/list',  params)
  }

  getDetailRS(id) {
    return API.get(`hospital_blood_receivers/detail/${id}`)
  }

  addRSDropping(payload) {
    return API.post('hospital_blood_receivers/list/store', payload)
  }

  updateRSDropping(payload) {
    return API.post('hospital_blood_receivers/list/update', payload)
  }

  deleteRSDropping(payload) {
    return API.post('hospital_blood_receivers/list/delete', payload)
  }
}

export default new KelolaRumahSakitService()
