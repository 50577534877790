<template>
  <div class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <div cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Lokasi dan Operasional</h2>
    </div>

    <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
      <b-col
        cols="lg-6 sm-12"
        class="pl-16 pl-sm-32 py-14 py-sm-12 pl-sm-32 overflow-hidden"
      >
        <ul>
          <li>
            <p class="text-black-60">Nama Rumah Sakit</p>
            <p class="text-p1-body">
              {{ currentInfoRumahSakit.name }}
            </p>
          </li>
          <li>
            <p class="text-black-60">Alamat Rumah Sakit</p>
            <p class="text-p1-body">
              {{ currentInfoRumahSakit.address }}
            </p>
            <iframe
              :src="`https://maps.google.com/maps?q=${markerLatLng[0]},${markerLatLng[1]}&hl=es&z=14&amp;output=embed`"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </li>
        </ul>
      </b-col>

      <b-col
        cols="lg-6 sm-12"
        class="pl-16 pl-sm-32 py-14 py-sm-12 py-sm-12 overflow-hidden"
      >
        <ul>
          <li>
            <p class="text-black-60">Layanan</p>
            <p class="text-p1-body">
              {{ currentInfoRumahSakit.service }}
            </p>
          </li>
          <li>
            <p class="text-black-60">Kontak Rumah Sakit</p>
            <p class="text-p1-body">
              {{ currentInfoRumahSakit.contact }}
            </p>
          </li>
          <li>
            <p class="text-black-60">Email</p>
            <p class="text-p1-body">
              {{ currentInfoRumahSakit.email }}
            </p>
          </li>
          <li>
            <p class="text-black-60">Jadwal Operasional</p>
            <div
              v-for="singleSchedule in operations"
              :key="singleSchedule.day"
              class="d-flex align-items-center justify-content-around"
            >
              <p>{{ singleSchedule.day }}</p>
              <ul class="d-flex">
                <li>
                  {{
                    singleSchedule.open_hour +
                    " : " +
                    singleSchedule.open_minute
                  }}
                </li>
                -
                <li>
                  {{
                    singleSchedule.close_hour +
                    " : " +
                    singleSchedule.close_minute
                  }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end w-100">
      <router-link to="/dashboards/edit-hospital">
        <b-button class="my-5" size="lg" variant="success-1">
          <i class="ri-edit-box-line mx-2" />
          Edit
        </b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BButton } from "bootstrap-vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { latLng } from "leaflet";
import KelolaRumahSakit from "../../../../api/managehospital/manageHospitalAPI";

export default {
  name: "ViewHospital",
  components: {
    BRow,
    BCol,
    BButton,
    LMarker,
    LMap,
    LTileLayer,
  },
  data() {
    return {
      currentInfoRumahSakit: null,
      image_path: "",
      operations: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 25,
      center: [51.505, -0.159],
      markerLatLng: [51.505, -0.159],
      loadingMap: true,
      withPopup: latLng(-6.919959, 107.618084),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(-6.919959, 107.618084),
      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
  created() {
    this.getDetailRumahSakit();
  },
  methods: {
    getDetailRumahSakit() {
      KelolaRumahSakit.get()
        .then((response) => {
          this.currentInfoRumahSakit = response.data.data;
          this.image_path = `${process.env.VUE_APP_BASE_URL}${response.data.data.image}`;
          this.operations = response.data.data.schedule;

          this.center[0] = response.data.data.latitude;
          this.center[1] = response.data.data.longitude;

          this.markerLatLng[0] = response.data.data.latitude;
          this.markerLatLng[1] = response.data.data.longitude;

          const scheduleArray = JSON.parse(this.operations);

          const filteredSchedules = scheduleArray.filter((schedule) => {
            const day = schedule.day.toLowerCase();
            return (
              day === "senin" ||
              day === "selasa" ||
              day === "rabu" ||
              day === "kamis" ||
              day === "jumat" ||
              day === "sabtu" ||
              day === "minggu"
            );
          });

          this.operations = filteredSchedules;
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
